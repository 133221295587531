module.exports = {
  siteTitle: 'Gangi.xyz', // <title>
  manifestName: 'Identity',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  authorName: 'Briana Gangi',
  heading: 'Not a Developer',
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/aruex',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/supertiredd',
    },
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://linkedin.com/brigangi',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:site@brigangi.com',
    },
  ],
};
